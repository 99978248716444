import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { Button, Modal, notification } from 'antd'

import {
  AuthorizeStoreContext,
  UserDetailContext,
  LinkCalendarStoreContext,
} from 'store'
import { noop } from 'config/utils'

const confirm = Modal.confirm

const socialCal = [
  {
    name: 'microsoft',
    id: 'microsoft',
  },
  {
    name: 'google',
    id: 'gmail',
  },
]

const isBtnDisabled = (user, social) =>
  user?.defaultCalendar?.length > 0 && user.defaultCalendar !== social.id

const SocialCalendar = () => {
  const { getAuthURL } = useContext(AuthorizeStoreContext)
  const { user, modifyDefaultCalendar } = useContext(UserDetailContext)
  const { removeCalendar } = useContext(LinkCalendarStoreContext)

  const linkCalendar = async authName => {
    try {
      const res = await getAuthURL(authName)
      localStorage.setItem('source', res.source)
      window.location.href = res.authUrl
    } catch (e) {
      const { statuscode, message } = e.response.data
      if (statuscode !== 200) {
        notification.error({
          message,
        })
      }
    }
  }

  const removeLinkedCalendar = () => {
    confirm({
      title: 'Are you sure you want to unlink your calendar?',
      okText: 'Yes',
      okType: 'danger',
      async onOk() {
        try {
          let res = await removeCalendar()
          modifyDefaultCalendar()
          return notification.success({
            message: 'Success.',
            description: res,
          })
        } catch (e) {
          const { statuscode, message } = e.response.data
          if (statuscode !== 200) {
            return notification.error({
              message,
            })
          }
        }
      },
    })
  }

  return (
    <div className="linked-account-container">
      {socialCal.map(social => {
        return (
          <div className="account" key={social.id}>
            <h3>{social.name}</h3>
            {user?.defaultCalendar !== social.id ? (
              <Button
                disabled={isBtnDisabled(user, social)}
                onClick={() => linkCalendar(social.name)}
              >
                Add
              </Button>
            ) : (
              <Button onClick={removeLinkedCalendar}>Remove</Button>
            )}
          </div>
        )
      })}
    </div>
  )
}

SocialCalendar.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  linkCalendar: PropTypes.func,
}

SocialCalendar.defaultProps = {
  user: {},
  loading: false,
  linkCalendar: noop,
}

export default observer(SocialCalendar)
