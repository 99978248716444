import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, notification } from 'antd'

import { AuthorizeStoreContext } from 'store'
import { images } from 'config/images'
import { isProd } from 'config/utils'

const LinkedinLogin = props => {
  const { getAuthorization } = useContext(AuthorizeStoreContext)

  const handleLogin = async () => {
    try {
      const link = await getAuthorization()
      localStorage.setItem('source', link.source)
      if (link.source === 'linkedin') {
        window.location.href = link.authUrl
      }
    } catch (e) {
      const { statuscode, message } = e.response.data
      if (statuscode === 400 || statuscode === 500 || statuscode === 404) {
        notification.error({
          message,
        })
        localStorage.clear()
        window.location.href = isProd ? 'https://meetingmachine.me' : '/'
      }
    }
  }

  return (
    <Button onClick={handleLogin} className="login-button">
      <img
        src={images.signin__button}
        alt="login button"
        className="linkedin-login"
      />
    </Button>
  )
}

export default observer(LinkedinLogin)
