import React from 'react'
import { Link } from 'react-router-dom'

import { isProd } from 'config/utils'

const Logout = () => {
  const handleLogout = () => {
    localStorage.clear()
  }

  if (isProd) {
    return (
      <a
        href="https://stage.meetingmachine.co/"
        onClick={handleLogout}
        className="logout-link"
      >
        <p>Logout</p>
      </a>
    )
  }

  return (
    <Link to="/" onClick={handleLogout} className="logout-link">
      <p>Logout</p>
    </Link>
  )
}

export default Logout
