import { observable, decorate, action } from 'mobx'
import {
  interestGroupSearchService,
  introPreferencesService,
} from '../services/interestGroupSearchService'
import { createContext } from 'react'

class InterestGroup {
  groups = []

  interestGroupSearch = async searchTerm => {
    this.groups = await interestGroupSearchService(searchTerm)
    return this.groups
  }

  introPreferences = async (userid, groupid, preference) => {
    return await introPreferencesService(userid, groupid, preference)
  }
}

decorate(InterestGroup, {
  groups: observable,
  interestGroupSearch: action,
  introPreferences: action,
})

export default createContext(new InterestGroup())
