import React, { Fragment } from 'react'
import { Button, Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import { Steppers } from 'components'

const OnboardingUserModal = ({ show, openModal, closeModal }) => {
  return (
    <Fragment>
      <Button onClick={openModal} shape="circle" icon="edit" />
      <Modal
        title={
          <>
            <div style={{ textAlign: 'center' }}>
              Complete all steps to receive FREE professional intro invitations
            </div>
            <div style={{ textAlign: 'center', fontSize: '15px' }}>
              (Charged $1.99 only when you &amp; your match both accept
              invitation)*
            </div>
          </>
        }
        centered
        visible={show}
        maskClosable
        style={{ height: 530 }}
        width={
          window.innerWidth <= 850 && window.innerHeight <= 1024 ? '100%' : 1000
        }
        footer={null}
        onCancel={closeModal}
      >
        <Steppers close={closeModal} />
      </Modal>
    </Fragment>
  )
}

export default observer(OnboardingUserModal)
