import React from 'react'

import ProfileForm from '../ProfileForm/ProfileForm'
import SocialCalendar from '../SocialCalendar/SocialCalendar'
import Payment from '../Payment'

export default props => [
  {
    title: 'Profile Information.',
    content: <ProfileForm {...props} />,
  },
  {
    title: 'Link your calendar.',
    content: <SocialCalendar {...props} />,
  },
  {
    title: 'Payment Method.',
    content: <Payment {...props} />,
  },
]
