export const noop = () => {}

export const fake = {}

export const moreInfoText = {
  profile:
    'These fields are required to recieve FREE intros. We use these insights to help inform our professional recommendations for you.',
  linkedAccounts:
    'We use these data sources to automatically identify mutual time to coordinate intro meeting and help our recommendation algorithm find relevant professionals for you.',
  billing:
    'Meeting invites are 100% FREE to Recieve. We only charge $1.99 after both you and your Meeting Match accept the event.',
  interestGroup:
    'You can join or create a new professional group, this helps inform our professional recommendations for you. All public groups are searchable.',
}

export const isEmoji = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g

export const isProd = process.env.NODE_ENV === 'production'

export const baseUrl = isProd
  ? process.env.REACT_APP_PROD_URL
  : process.env.REACT_APP_DEV_URL

export const stripeKey = isProd
  ? process.env.REACT_APP_PROD_STRIPE_KEY
  : process.env.REACT_APP_DEV_STRIPE_KEY
