import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Modal, notification } from 'antd'

import { DeleteAccountStoreContext } from 'store'
import { isProd } from 'config/utils'

const confirm = Modal.confirm

const DeleteAccount = () => {
  const [isAccountDeleted, setAccountDeleted] = useState(false)

  const { deleteAccount } = useContext(DeleteAccountStoreContext)

  const handleDeleteAccount = () => {
    confirm({
      title: 'Are you sure you want to delete this account?',
      okText: 'Yes',
      okType: 'danger',
      async onOk() {
        try {
          await deleteAccount()
          localStorage.clear()
          setAccountDeleted(true)

          return notification.success({
            message: 'Account deleted.',
          })
        } catch (e) {
          const { statuscode, message } = e.response.data

          if (statuscode !== 200) {
            return notification.error({
              message,
            })
          }
        }
      },
    })
  }

  if (isAccountDeleted) {
    if (isProd) {
      window.location.href = 'https://stage.meetingmachine.co/'
    } else {
      return <Redirect to="/" />
    }
  }

  return (
    <Button type="danger" onClick={handleDeleteAccount}>
      Delete Account
    </Button>
  )
}

export default DeleteAccount
