import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'

import { Logo, Logout } from 'components'

import './Header.scss'

const { Header } = Layout

const HeaderMain = () => {
  return (
    <Header id="header">
      <div className="header-section">
        <div className="header-section__left">
          <Link
            to={localStorage.getItem('access-token') ? '/dashboard' : '/'}
            className="logo-section"
          >
            <Logo />
          </Link>
        </div>
        <div className="header-section__right">
          <Logout />
        </div>
      </div>
    </Header>
  )
}

HeaderMain.propTypes = {
  isLoggedIn: PropTypes.bool,
}

HeaderMain.defaultProps = {
  isLoggedIn: false,
}

export default HeaderMain
