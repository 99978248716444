import React, { useContext, useState } from 'react'
import { Form, Button, Switch, notification } from 'antd'

import { InputContainer } from 'components'
import { JoinGroupStoreContext } from 'store'
import './CreateNewGroup.scss'
import { isEmoji } from 'config/utils'

const CreateNewGroup = ({ form, handleNewGroup }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [checked] = useState(false)

  const { createGroup } = useContext(JoinGroupStoreContext)

  const { getFieldDecorator } = form

  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  }

  const handleCreateNewGroup = e => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) {
        setIsLoading(true)
        try {
          const res = await createGroup(
            values.group_name.trim(),
            !values.private_group,
            'A new group.'
          )
          form.resetFields()
          handleNewGroup(res, true)
          setIsLoading(false)
          notification.success({
            message: 'New Group Created.',
          })
        } catch (e) {
          const { statuscode, message } = e.response.data
          setIsLoading(false)
          if (statuscode !== 200) {
            setIsLoading(false)
            notification.error({
              message,
            })
          }
        }
      }
    })
  }

  const validations = (rule, value, callback) => {
    const onlyAllowAlphabetsAndDash = /^[A-Za-z -]+$/i
    if (value?.trim()?.length === 0) {
      callback('Whitespaces are not valid.')
    } else if (value && (value.length < 3 || value.length >= 25)) {
      callback('You can enter Maximum 25 characters and Minimum 3 characters!')
    } else if (value && value[0] && !value[0].match(/^[A-Za-z]+$/i)) {
      callback(
        'Group name should start with only an alphabet and can contain "-".'
      )
    } else if (value && !value.match(onlyAllowAlphabetsAndDash)) {
      callback('Only alphabets and dash are allowed.')
    } else if (value && value.match(isEmoji)) {
      callback('Special Characters are not allowed')
    } else {
      callback()
    }
  }

  return (
    <Form
      {...formItemLayout}
      onSubmit={handleCreateNewGroup}
      labelAlign="left"
      className="create-new-group-form"
    >
      <Form.Item className="form-item" label="Group Name">
        {getFieldDecorator('group_name', {
          rules: [
            {
              message: 'Group Name is required.',
              required: true,
            },
            {
              validator: validations,
            },
          ],
        })(<InputContainer />)}
      </Form.Item>
      <Form.Item label="Private Group">
        {getFieldDecorator('private_group', {
          valuePropName: 'checked',
          initialValue: checked,
        })(<Switch />)}
      </Form.Item>
      <div className="create-button-container">
        <Button loading={isLoading} htmlType="submit">
          Create
        </Button>
      </div>
    </Form>
  )
}

export default Form.create({ name: 'create_new_group' })(CreateNewGroup)
