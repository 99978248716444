import { observable, decorate, action } from 'mobx'
import {
  joinGroupService,
  createNewGroupService,
} from '../services/joinGroupService'
import { createContext } from 'react'

class JoinGroup {
  joinedGroups = []
  successMessage = ''

  joinGroup = async (groupID, userID) => {
    this.successMessage = await joinGroupService(groupID, userID)
    return this.successMessage
  }

  createGroup = async (groupName, isPublic, description) => {
    this.groups = await createNewGroupService(groupName, isPublic, description)
    return this.groups
  }
}

decorate(JoinGroup, {
  joinedGroups: observable,
  joinGroup: action,
})

export default createContext(new JoinGroup())
