import React from 'react'
import PropTypes from 'prop-types'

import './PaymentCardInformation.scss'

const PaymentCardInformation = ({ card }) => {
  return (
    <div className="card-information-container">
      <h2>{card.brand}</h2>
      <h2>{`#${card.last4}`}</h2>
    </div>
  )
}

PaymentCardInformation.propTypes = {
  card: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default PaymentCardInformation
