import React, { useContext } from 'react'
import { Modal, notification } from 'antd'
import { observer } from 'mobx-react-lite'

import { BillingStoreContext, UserDetailContext } from 'store'
import { noop } from 'config/utils'

import './CancelSubscription.scss'

const confirm = Modal.confirm

const CancelSubscription = () => {
  const { cancelSubscription } = useContext(BillingStoreContext)

  const { modifyVerifiedPayment } = useContext(UserDetailContext)

  const handleCancelSubscription = () => {
    confirm({
      title: 'Are you sure you want to cancel this subscription?',
      okText: 'Yes',
      okType: 'danger',
      async onOk() {
        try {
          const res = await cancelSubscription()
          modifyVerifiedPayment({}, false)
          notification.success({
            message: 'Subscription Cancelled.',
            description: res,
          })
        } catch (e) {
          const { statuscode, message } = e.response.data
          if (statuscode !== 200) {
            notification.error({
              message,
            })
          }
        }
      },
    })
  }

  return (
    <div className="cancel-subscription-container">
      <h3
        role="presentation"
        onKeyDown={noop}
        onClick={handleCancelSubscription}
      >
        Cancel Subscription
      </h3>
    </div>
  )
}

export default observer(CancelSubscription)
