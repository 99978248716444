import React, { Fragment } from 'react'
import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'

import { ShareViaEmail } from 'components'

import { noop } from 'config/utils'

const ShareGroup = ({ openModal, show, closeModal, groupToShare }) => {
  const shareViaLinkedin = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${groupToShare}`,
      '_blank',
      'width=700, height=500, left=700, top=280'
    )
  }
  return (
    <Fragment>
      <Button onClick={openModal}>Share</Button>
      <Modal
        title="Share Group:"
        centered
        visible={show}
        maskClosable
        footer={null}
        onCancel={closeModal}
      >
        <div className="centered">
          <Button onClick={shareViaLinkedin}>Share via Linkedin</Button>
          <ShareViaEmail content={groupToShare} />
        </div>
      </Modal>
    </Fragment>
  )
}

ShareGroup.propTypes = {
  openModal: PropTypes.func,
  show: PropTypes.bool,
  closeModal: PropTypes.func,
}

ShareGroup.defaultProps = {
  openModal: noop,
  show: false,
  closeModal: noop,
}

export default ShareGroup
