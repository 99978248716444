import { observable, action, decorate } from 'mobx'
import {
  getUserDetailService,
  postUserDetailUpdate,
  searchService,
} from '../services/userDetailService'
import { createContext } from 'react'

class UserDetail {
  user = null
  error = null
  searchedData = []

  search = async (searchType, searchTerm) => {
    this.searchedData = await searchService(searchType, searchTerm)
    return this.searchedData
  }

  flushSearchedData = () => {
    this.searchedData = []
    return this.searchedData
  }

  getUserDetail = async codeObj => {
    this.user = await getUserDetailService(codeObj)
    return this.user
  }

  postUserUpdate = async ({ id, title, location, company, industry }) => {
    const userUpdatedValues = await postUserDetailUpdate(
      id,
      title,
      location,
      company,
      industry
    )

    const user = {
      ...this.user,
      title: userUpdatedValues.title,
      location: userUpdatedValues.location,
      company: userUpdatedValues.company,
      industry: userUpdatedValues.industry,
    }

    this.user = user

    return this.user
  }

  modifyVerifiedPayment = (cardObj, modify) => {
    const modifiedUser = {
      ...this.user,
      isPaymentVerified: modify,
      card: cardObj,
    }

    this.user = modifiedUser

    return this.user
  }

  modifyDefaultCalendar = () => {
    const modifiedDefaultCalendar = {
      ...this.user,
      defaultCalendar: '',
    }

    this.user = modifiedDefaultCalendar

    return this.user
  }

  modifyGroupDetails = res => {
    this.user = res

    return this.user
  }

  modifyIntroPreferences = res => {
    const modifiedUser = {
      ...this.user,
      introPreference: res.introPreference,
    }

    this.user = modifiedUser

    return this.user
  }
}

decorate(UserDetail, {
  user: observable,
  error: observable,
  searchedData: observable,
  flushSearchedData: action,
  search: action,
  postUserUpdate: action,
  getUserDetail: action,
  modifyVerifiedPayment: action,
  modifyDefaultCalendar: action,
  modifyGroupDetails: action,
  modifyIntroPreferences: action,
})

export default createContext(new UserDetail())
