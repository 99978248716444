import { get, post } from './index'

export const getUserDetailService = async codeObj => {
  const response = await post({
    url: `/linkedin/get-user-detail`,
    body: codeObj,
  })

  return response.data || {}
}

export const postUserDetailUpdate = async (
  id,
  title,
  location,
  company,
  industry
) => {
  const response = await post({
    url: `/user/update`,
    body: {
      id,
      title,
      location,
      company,
      industry,
    },
  })
  return response.data || {}
}

export const searchService = async (searchType, searchTerm) => {
  const response = await get({
    url: `/get-${searchType}?searchTerm=${searchTerm}`,
  })

  return response.data || {}
}
