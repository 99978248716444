import React, { useContext } from 'react'
import { Select, Row, Col, Form, Button, notification } from 'antd'

import { InputContainer } from 'components'
import ProfileFields from './ProfileFields'

import { UserDetailContext } from 'store'
import { observer } from 'mobx-react-lite'

const Option = Select.Option

const ProfileForm = ({ form, next }) => {
  const {
    user,
    postUserUpdate,
    search,
    searchedData,
    flushSearchedData,
  } = useContext(UserDetailContext)

  const { getFieldDecorator, getFieldsError } = form

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  const fieldSearch = async (fieldName, value) => {
    if (value.length >= 2) {
      try {
        await search(fieldName, value)
      } catch (e) {
        const { statuscode, message } = e.response.data
        if (statuscode !== 200) {
          notification.error({
            message,
          })
        }
      }
    }
  }

  const handleProfileSubmit = e => {
    const userID = localStorage.getItem('userID')

    e.preventDefault()

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await postUserUpdate({
            id: userID,
            title: values?.title?.trim(),
            location: values?.location?.trim(),
            company: values?.company?.trim(),
            industry: values?.industry?.trim(),
          })
          if (next) {
            next()
          }
        } catch (e) {
          const { statuscode, message } = e.response.data
          if (statuscode !== 200) {
            notification.error({
              message,
            })
          }
        }
      }
    })
  }

  const validations = (rule, value, callback) => {
    const onlyAllowAlphaNumericAndSpaces = /^[A-Za-z0-9 ;,._&()-]+$/i
    if (value?.trim()?.length === 0) {
      callback('Whitespaces are not valid.')
    } else if (value?.length >= 40) {
      callback('Maximum 40 characters only!')
    } else if (!value?.match(onlyAllowAlphaNumericAndSpaces)) {
      callback('Only alphanumeric, round brackets and spaces are allowed.')
    } else {
      callback()
    }
  }

  return (
    <Form
      className="profile-form-container"
      onSubmit={e => handleProfileSubmit(e)}
    >
      <Row gutter={24}>
        {ProfileFields(
          user?.title,
          user?.location?.[0],
          user?.company,
          user?.industry?.[0]
        ).map(field => (
          <Col
            span={
              window.innerWidth <= 850 && window.innerHeight <= 1024 ? 24 : 12
            }
            key={field.name}
          >
            <Form.Item label={field.label}>
              {getFieldDecorator(field.name, {
                rules: [field.rules, { validator: validations }],
                initialValue: field.initialValue,
              })(
                field.name === 'location' || field.name === 'industry' ? (
                  <Select
                    showSearch
                    placeholder={field.placeholder}
                    style={{ width: '100%', textTransform: 'capitalize' }}
                    size="large"
                    showArrow={false}
                    onBlur={flushSearchedData}
                    getPopupContainer={trigger => trigger.parentNode}
                    onSearch={value => fieldSearch(field.name, value)}
                  >
                    {searchedData.map(search => (
                      <Option
                        value={search.location || search.industry}
                        key={search.location || search.industry}
                      >
                        {search.location || search.industry}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <InputContainer
                    placeholder={field.placeholder}
                    size={field.size}
                  />
                )
              )}
            </Form.Item>
          </Col>
        ))}
      </Row>
      <div className="save-button-container">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            Save
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default Form.create({ name: 'profile_form' })(observer(ProfileForm))
