import React, { useState, useContext } from 'react'
import {
  Button,
  Select,
  Modal,
  notification,
  message,
  Divider,
  Icon,
  Switch,
} from 'antd'
import { observer } from 'mobx-react-lite'
import Clipboard from 'react-clipboard.js'

import { Block, CreateNewGroup, ShareGroup } from 'components'

import {
  InterestGroupStoreContext,
  JoinGroupStoreContext,
  UserDetailContext,
} from 'store'

import { moreInfoText, isProd } from 'config/utils'
import './InterestGroupSearchBlock.scss'

const Option = Select.Option

const InterestGroupSearchBlock = ({ history }) => {
  const [joinGroupLoading, setJoinGroupLoading] = useState(false)

  const [showActionModal, setShowActionModal] = useState(false)

  const [showInfoModal, setShowInfoModal] = useState(false)

  const [showNewGroupModal, setShowNewGroupModal] = useState(false)

  const [showPreferencesModal, setShowPreferencesModal] = useState(false)

  const [showShareGroup, setShowShareGroup] = useState(false)

  const [clickedGroup, setClickedGroup] = useState({})

  const [newGroup, setNewGroup] = useState({})

  const [onlyShare, setOnlyShare] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const { groups, interestGroupSearch, introPreferences } = useContext(
    InterestGroupStoreContext
  )
  const { joinGroup } = useContext(JoinGroupStoreContext)
  const { user, modifyGroupDetails, modifyIntroPreferences } = useContext(
    UserDetailContext
  )

  const onSwitchChange = async event => {
    setIsLoading(true)
    try {
      const res = await introPreferences(
        user.id,
        user?.groupdetails?.[0]?.group.id,
        event
      )
      modifyIntroPreferences(res)
      notification.success({
        message: 'Intro Preference changed successfully.',
        description: 'Weekly Intros to relevent business professionals.',
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      const { message } = e.response.data
      notification.error({
        message,
      })
    }
  }
  const searchInterestGroup = async value => {
    const searchedValue = value.trim()
    const isMatched = searchedValue.match(/^[A-Za-z0-9 -]+$/i)
    if (isMatched && searchedValue.length >= 3) {
      try {
        await interestGroupSearch(value)
      } catch (e) {
        const { statuscode, message } = e.response.data
        if (statuscode === 400) {
          notification.error({
            message,
          })
          localStorage.clear()
          setTimeout(() => {
            window.location.href = isProd
              ? 'https://stage.meetingmachine.co/'
              : '/'
          }, 500)
        } else if (statuscode === 500) {
          notification.error({
            message,
          })
        }
      }
    }
  }

  const handleClickedGroup = (option, openInfoModal = false) => {
    if (typeof option === 'string') {
      const newOption = JSON.parse(option)
      setClickedGroup(newOption)
      setNewGroup(newOption)
    } else {
      setClickedGroup(option)
      setNewGroup(option)
    }
    if (openInfoModal) {
      setShowNewGroupModal(false)
      setShowInfoModal(true)
    } else {
      setShowActionModal(true)
    }
  }

  const handleNewGroup = (group, openInfoModal = false) => {
    setNewGroup(group)
    if (openInfoModal) {
      setShowNewGroupModal(false)
      setShowInfoModal(true)
    } else {
      setShowActionModal(true)
    }
  }

  const handleGetInfoGroup = () => {
    setShowActionModal(false)
    setShowInfoModal(true)
  }

  const handleJoinGroup = async () => {
    setJoinGroupLoading(true)
    try {
      const res = await joinGroup(newGroup.id, user.id)

      setJoinGroupLoading(false)
      if (res === 'User already in group') {
        notification.warning({
          message: 'Already Joined',
        })
      } else {
        notification.success({
          message: 'Joined',
        })
        modifyGroupDetails(res)
      }

      setClickedGroup({})
      setShowActionModal(false)
      setShowInfoModal(false)
    } catch (e) {
      setJoinGroupLoading(false)

      const { statuscode, message } = e.response.data

      if (statuscode === 400 || statuscode === 500) {
        notification.error({
          message,
        })
        localStorage.clear()
        window.location.href = isProd ? 'https://meetingmachine.me' : '/'
      } else if (statuscode === 404) {
        notification.error({
          message,
        })
        history.push('/dashboard')
      }
    }
  }

  const showModal = group => {
    setNewGroup(group)
    setShowActionModal(false)
    setShowInfoModal(true)
    setOnlyShare(true)
  }

  return (
    <Block
      blockName="Professional Group"
      tooltipPlacement="right"
      tooltipText={moreInfoText.interestGroup}
    >
      <div className="interest-search-group-container">
        <Select
          showSearch
          placeholder="Search, join or create a Professional Group"
          style={{ width: '100%' }}
          size="large"
          value={
            Object.keys(clickedGroup).length > 0
              ? JSON.stringify(clickedGroup)
              : undefined
          }
          showArrow={false}
          onSearch={searchInterestGroup}
          onChange={e => handleClickedGroup(e)}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onMouseDown={() => setShowNewGroupModal(true)}
              >
                <Icon type="plus" />
                <p>Create New Group</p>
              </div>
            </div>
          )}
        >
          {groups.map(option => (
            <Option value={JSON.stringify(option)} key={option.groupName}>
              {option.groupName}
            </Option>
          ))}
        </Select>
        {user?.groupdetails?.[0]?.group && (
          <div className="default-group-container">
            <p
              className="default-group-name"
              onClick={() => showModal(user.groupdetails[0].group)}
            >{`#${user.groupdetails[0].group.groupName}`}</p>
            <div className="default-actions">
              <Clipboard
                component={Button}
                data-clipboard-text={`https://${
                  user.groupdetails[0].group.groupLink
                }`}
                onSuccess={() => message.success('Link Copied!')}
                onError={() => message.error('Link cannot be copied.')}
              >
                Copy Link
              </Clipboard>
              <ShareGroup
                openModal={() => setShowShareGroup(true)}
                show={showShareGroup}
                groupToShare={`https://${user.groupdetails[0].group.groupLink}`}
                closeModal={() => setShowShareGroup(false)}
              />
              <Button onClick={() => setShowPreferencesModal(true)}>
                Preferences
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal
        title="Would you like to:"
        centered
        visible={showActionModal}
        maskClosable
        footer={null}
        onCancel={() => {
          setShowActionModal(false)
          setNewGroup({})
          setClickedGroup({})
        }}
      >
        <div className="centered">
          <Button loading={joinGroupLoading} onClick={handleJoinGroup}>
            Join Group
          </Button>
          <p>or</p>
          <Button onClick={handleGetInfoGroup}>Get Group Info</Button>
        </div>
      </Modal>
      <Modal
        title="Group Info:"
        centered
        visible={showInfoModal}
        maskClosable
        footer={null}
        onCancel={() => {
          setShowInfoModal(false)
          setOnlyShare(false)
        }}
      >
        <div>
          <p>Group Name: {newGroup.groupName}</p>
          <p>
            Group Link:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${newGroup.groupLink}`}
            >
              https://{newGroup.groupLink}
            </a>
          </p>
        </div>
        <div className="centered">
          {onlyShare && (
            <Button
              onClick={() => {
                setShowPreferencesModal(true)
                setShowInfoModal(false)
                setOnlyShare(false)
              }}
            >
              Preferences
            </Button>
          )}
          {!showShareGroup && ( //to prevent multiple render
            <ShareGroup
              openModal={() => {
                setShowShareGroup(true)
                setShowInfoModal(false)
                setOnlyShare(false)
              }}
              show={showShareGroup}
              groupToShare={newGroup.groupLink}
              closeModal={() => setShowShareGroup(false)}
            />
          )}
          {!onlyShare && (
            <>
              <p>or</p>
              <Button loading={joinGroupLoading} onClick={handleJoinGroup}>
                Join Group
              </Button>
            </>
          )}
        </div>
      </Modal>
      <Modal
        title="Create New Group:"
        centered
        visible={showNewGroupModal}
        maskClosable
        footer={null}
        destroyOnClose
        onCancel={() => setShowNewGroupModal(false)}
      >
        <CreateNewGroup handleNewGroup={handleNewGroup} />
      </Modal>
      <Modal
        title="Preferences:"
        centered
        visible={showPreferencesModal}
        maskClosable
        footer={null}
        destroyOnClose
        onCancel={() => setShowPreferencesModal(false)}
      >
        <div className="centered">
          Intros within group only:
          <Switch
            loading={isLoading}
            onChange={onSwitchChange}
            checked={user.introPreference}
          />
        </div>
      </Modal>
    </Block>
  )
}

export default observer(InterestGroupSearchBlock)
