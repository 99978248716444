import { get, post } from './index'

export const interestGroupSearchService = async searchTerm => {
  const response = await get({
    url: `/group/search?searchTerm=${searchTerm}`,
  })

  return response.data || {}
}

export const introPreferencesService = async (userid, groupid, preference) => {
  const response = await post({
    url: `/group/intro-preferences`,
    body: {
      userid,
      groupid,
      preference,
    },
  })
  return response.data
}

export const fake = () => {}
