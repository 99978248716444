import { lazy } from 'react'

export const routes = {
  home: {
    path: '/',
    exact: true,
    component: lazy(() => import('../pages/HomePage/HomePage')),
  },
  dashboard: {
    path: '/dashboard',
    exact: true,
    component: lazy(() => import('../pages/DashboardPage/DashboardPage')),
  },
  intermediate: {
    path: '/intermediate',
    exact: true,
    component: lazy(() => import('../pages/IntermediatePage/IntermediatePage')),
  },
  group: {
    path: '/group/:id',
    exact: true,
    component: lazy(() => import('../pages/GroupPage/GroupPage')),
  },
}

export const renderRoutes = Object.entries(routes)
