import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import {
  PaymentCardInformation,
  CancelSubscription,
  SubscribeUser,
  UpdateCard,
} from 'components'

import { UserDetailContext } from 'store'

const Payment = () => {
  const { user } = useContext(UserDetailContext)

  return (
    <div className="payment-container">
      {user?.isPaymentVerified ? (
        <div className="payment-information-container">
          <PaymentCardInformation card={user.card} />
          <CancelSubscription />
          <UpdateCard />
        </div>
      ) : (
        <SubscribeUser />
      )}
    </div>
  )
}

export default observer(Payment)
