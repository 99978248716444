import { post } from './index'

export const joinGroupService = async (groupid, userid) => {
  const response = await post({
    url: '/group/add-member',
    body: {
      groupid,
      userid,
    },
  })
  return response.data || {}
}

export const createNewGroupService = async (
  groupName,
  isPublic,
  description
) => {
  const response = await post({
    url: '/group/create',
    body: { groupName, isPublic, description },
  })
  return response.data || {}
}

export const fake = () => {}
