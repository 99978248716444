import { observable, decorate, action } from 'mobx'
import {
  linkCalendarService,
  removeCalendarService,
} from '../services/linkCalendarService'
import { createContext } from 'react'

class LinkCalendar {
  authURL = ''

  linkCalendar = async (authName, code, userid) => {
    this.authURL = await linkCalendarService(authName, code, userid)
    return this.authURL
  }

  removeCalendar = async () => {
    this.authURL = await removeCalendarService()
    return this.authURL
  }
}

decorate(LinkCalendar, {
  authURL: observable,
  linkCalendar: action,
  removeCalendar: action,
})

export default createContext(new LinkCalendar())
