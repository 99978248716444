import React, { useContext, useState, useEffect } from 'react'
import StripeCheckout from 'react-stripe-checkout'
import { observer } from 'mobx-react-lite'
import { notification } from 'antd'

import { Spinner } from 'components'

import { BillingStoreContext, UserDetailContext } from 'store'
import { images } from 'config/images'
import { stripeKey } from 'config/utils'

const UpdateCard = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { updateCustomerCard } = useContext(BillingStoreContext)
  const { modifyVerifiedPayment, user } = useContext(UserDetailContext)

  useEffect(() => {
    return () => setIsLoading(false)
  }, [])

  const onToken = async (token, addresses) => {
    setIsLoading(true)
    try {
      const res = await updateCustomerCard(
        token.id,
        token.card.brand,
        token.card.last4,
        token.email
      )
      modifyVerifiedPayment(res, true)
      notification.success({
        message: 'Card details updated Successfully.',
        description: 'Weekly Intros to relevent business professionals.',
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      const { statuscode, message } = e.response.data
      if (statuscode !== 200) {
        notification.error({
          message,
        })
      }
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  const { email } = user

  return (
    <StripeCheckout
      image={images.app__logo}
      locale="auto"
      label="Update Card"
      panelLabel="Update Card"
      email={email}
      ComponentClass="billing-container"
      name="Meeting Machine"
      description="INSTANT MEETINGS WITH 
              RELEVANT PROFESSIONALS"
      stripeKey={stripeKey}
      token={onToken}
    />
  )
}

export default observer(UpdateCard)
